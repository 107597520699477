<template>
    <div class="campaign-mail">
       <p class="text-sm">You have selected {{selected.length}} order{{(selected.length>1)? 's':''}} to run email marketing</p>
        <vs-divider/>
        <div v-if="result.length" class="vx-row">
            <div class="vx-col sm:w-2/3 w-full mb-sm-0 mb-3">
                <h3 class="text-success mb-3"> Sent Result</h3>
                <VuePerfectScrollbar style="width: 100%; height: 270px;" class="border border-solid d-theme-border-grey-light"
                 :settings="settings" :key="$vs.rtl">
                    <div class="p-3">
                        <div class="mb-3" v-for="(res, rIndex) in result" :key="rIndex">
                            <h5>{{res.Message}}</h5>
                            <p>{{res.To}}</p>
                        </div>
                    </div>
                </VuePerfectScrollbar>
            </div>
        </div>
        <div v-else class="vx-row">
            <div class="vx-col sm:w-2/3 w-full mb-sm-0 mb-4">
                <VuePerfectScrollbar style="width: 100%; height: 270px;" class="border border-solid d-theme-border-grey-light"
                 :settings="settings" :key="$vs.rtl">
                    <div class="p-3">
                        <div class="mb-3" v-for="(buyer, bIndex) in buyers" :key="bIndex">
                            <h4>{{buyer.first_name}}</h4>
                            <p>{{buyer.email}}</p>
                        </div>
                    </div>
                </VuePerfectScrollbar>
            </div>
             <div class="vx-col sm:w-1/3 w-full mb-sm-0 mb-4">
                <vs-select autocomplete v-model="campaign" class="select-large w-full">
                    <vs-select-item :key="index" :value="item.value"
                    :text="item.text" v-for="(item, index) in campaignList" />
                </vs-select>
                <div class="mt-3 h-auto">
                    <vs-button class="ml-auto" @click="sendMail()">Send Mail</vs-button>
                </div>
            </div>
            
        </div>
        
    </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    data(){
        return {
            settings: {
                maxScrollbarLength : 60,
                wheelSpeed         : 0.70,
            },
            campaignList:[
                {value: 'promo',text: 'Promotion'},
                {value: 'advert',text: 'Advertisment'},
            ],
            campaign:"promo",
            result:[]
        }
    },
    props:{
        selected:{
            type: Array,
            required: true
        }
    },
    components: {
        VuePerfectScrollbar,
    },
    computed:{
        buyers(){
            return this.selected.map((se) => se.buyer);
        }
    },
    methods:{
        sendMail(){
            if(!this.buyers.length){ this.alertError("No buyer found"); return}
            const formData = new FormData()
            formData.append('data', JSON.stringify({buyers: this.buyers, campaign: this.campaign}))
            this.$vs.loading({color: "#444", type: "sound"})
            this.$http.post("/orders/send-mail",formData)
            .then(response => {
                this.$vs.loading.close()
                if(response.data.success){
                    this.result = [...response.data.data.result]
                }
            })
        },
    },
}
</script>