<template>
  <div class="flex items-center truncate">
    <router-link :to="'/orders/' + params.data.id" @click.stop.prevent class="text-inherit hover:text-primary">{{ params.value }}</router-link>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererOrderLink',
    }
</script>
