<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="$router.push({path: '/orders/' + params.data.id})" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDelete" />
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActions',
        methods: {
          confirmDelete() {
            this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: 'Are you sure?',
              text: `You are about to delete order with ID: "${this.params.data.id}"`,
              accept: this.deleteRecord,
              acceptText: "Delete"
            })
          },
          deleteRecord() {
            this.$vs.loading({color: "#444", type: "sound"})
            this.$store.dispatch("orderList/deleteOrder", this.params.data.id)
            .then(response => {
              if(response.data.success){
                this.$vs.loading.close()
              }
            })
          }
        }
    }
</script>
