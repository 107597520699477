<template>
  <div class="flex items-center">
  	<router-link :to="'/orders/' + params.data.id" @click.stop.prevent class="text-inherit hover:text-primary">{{params.data.currency_symbol}}{{ params.value }}</router-link>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererAmount',
    }
</script>
