<template>
  <div class="flex items-center h-full">
  	<router-link :to="'/orders/' + params.data.id" @click.stop.prevent class="text-inherit hover:text-primary">
	  	<div style="line-height: 1;" class="flex flex-col justify-center">
	  		<span>{{ params.value | date_parse(true) }} </span>
    		<span class="mt-1" style="font-size: 10px;">{{ params.value | time }}</span>
	    </div>
  	</router-link>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererDate',
    }
</script>
